import { Button } from '@c1/gravity-react';
import React, { useEffect, useState } from 'react';
import RouteBuilder from 'src/utils/routing/RouteBuilder';
import { useRouteBuilder } from 'src/utils/routing/RouteBuilderContext';
import { FunctionComponentReturnType } from 'src/types/sharedReact';
import { GetDashboardResponse} from "../../types/apiResponse";
import iframeResolutionHelper from 'src/utils/iframeResolutionHelper';
import axios, {AxiosError} from 'axios';
import { navigate, useLocation, useParams } from '@reach/router';
import EmbeddedTableau from 'src/components/EmbeddedTableau/EmbeddedTableau';
import {getFromApi} from "../../utils/getFromApi";
import { useNavigationPaneContext } from 'src/contextProviders/NavigationPaneProvider/NavigationPaneContext';
import "./TableauDashboard.css"

function TableauDashboard(): FunctionComponentReturnType {

    const [dashboard, setDashboard] = useState<GetDashboardResponse | null>(null);
    const [axiosError, setAxiosError] = useState<AxiosError | null>(null);
    const [errorOnLoad, setErrorOnLoad] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<String>('Something went wrong. Please contact the support team');
    const [dashboardUrl, setDashboardUrl] = useState<string>(null)
    const { dashboardId } = useParams()
    const [loading, setLoading] = useState<boolean>(true);
    const routeBuilder: RouteBuilder = useRouteBuilder();
    const apiRoute = routeBuilder.api.getDashboardById();
    const location = useLocation()
    const apiRouteFail: string = routeBuilder.api.trackDashboardFail({ dashboardId: dashboardId });
    const { isNavigationPaneOpen } = useNavigationPaneContext();

    let titleObject: any = !!location.state && typeof (location.state) === "object" ? location.state : { title: "Tableau View" };
    useEffect(() => {
        if (document.getElementById("page-template-title-element") && document.getElementById("page-template-title-element")!.textContent)
            document.getElementById("page-template-title-element")!.textContent = titleObject.title;
    }, []);

    useEffect(() => {
        iframeResolutionHelper(null, (iframeDoc, status) =>{
            let shouldSendFail = false;
            console.log("Status: " + status)

            if (status === 400 || status === 404 || status === 500 || status === 503) {
                setErrorOnLoad(true);
                setErrorMessage('Sorry - we are currently unable to process your request.  Please try again in a few minutes');
                setDashboard(null);
            }

           if (status == null && Math.floor(status/100) != 2) {
               shouldSendFail = true;
               console.log("Dashboard '" + dashboardId + "' failed because status=" + status);
           }
           if (iframeDoc) {
               try {
                   var element = iframeDoc.getElementsByTagName("app-controller")
                   shouldSendFail = !element;
               } catch (e) {
                   console.log("Dashboard '" + dashboardId + "' failed because error=" + e)
                   shouldSendFail = true;
               }
           } else {
               shouldSendFail = true;
               console.log("Dashboard '" + dashboardId + "' failed because iframe was not accessible")
           }
           if (shouldSendFail) {
               console.log("Dashboard '" + dashboardId + "' failed to load, sending error report")
               axios.post(apiRouteFail);
           } else {
               console.log("Dashboard '" + dashboardId + "' looks to have loaded successfully")
           }
        })
   }, []);

   useEffect(() => {
        if (dashboard?.dashboardUrl) {
            let url = dashboard?.dashboardUrl?.substr(dashboard?.dashboardUrl?.indexOf('/t'))
            url = location.origin + url;
            setDashboardUrl(sanitizeURL(url))

            resizeDashboard()
        }
    }, [dashboard]);

    useEffect(getFromApi(apiRoute, setDashboard, setAxiosError), [apiRoute]);

    useEffect(() => resizeDashboard(), [isNavigationPaneOpen]);

    function sanitizeURL(url: string): string {
        const validUrlPattern = /\/t\/[a-zA-Z0-9/-]+/;
        const match = validUrlPattern.test(url);
        return match ? encodeURI(url) : '';
    }

    function resizeDashboard() {
        // resize container div
        const menuWidth = isNavigationPaneOpen ? 'var(--menu-width-open)' : 'var(--menu-width-collapsed)'
        const tableauDiv = document.querySelector(".tableau-liveboard-container-div") as HTMLElement
        tableauDiv.style.width =  `calc(100vw - ${menuWidth})`;
        tableauDiv.style.left = `calc(-1 * (50vw - 50%) + ${menuWidth }/2)`;

        // if dashboard too big, scale iframe
        if (window.innerWidth < 1830) { // 1830px pre-set value
            const iframe = document.querySelector("#tableau-embed") as HTMLIFrameElement
            const container = document.querySelector(".tableau-liveboard-container-div") as HTMLElement

            if (iframe && container) {
                const scale = container.offsetWidth / 1830 // 183px pre-set value
                iframe.style.transform = `scale(${scale})`
                iframe.style.transformOrigin = "top left"
                iframe.style.width = `${100 / scale}%`
            }
        }
    }

    window.addEventListener('resize', resizeDashboard)
    window.addEventListener('change', resizeDashboard)

    const loader =
        <div className="tableau-loader-container">
            <div className="tableau-loader"></div>
        </div>

    return (
        <div>
            <Button id={'copp-button-return'} className="grv-margin--tiny" type="regressive" style={{marginBottom: '10px'}}
                    onClick={() => navigate(routeBuilder.client.toDashboardSelectionPage())} compact>Return</Button>
            <br/>
            <div className="tableau-liveboard-container-div">
                {loading && <div>{loader}</div>}
                {dashboard
                    ?
                    <iframe src={decodeURI(dashboardUrl)} id="tableau-embed" onLoad={() => setLoading(false)}></iframe>
                    :
                    errorOnLoad && <p>{errorMessage}</p>
                }
            </div>
        </div>
)
}

export default TableauDashboard;
