import { FunctionComponent, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { Container } from '@material-ui/core';
import { RouteComponentProps } from '@reach/router';
import RouteBuilderContext from 'src/utils/routing/RouteBuilderContext';
import RouteBuilder from 'src/utils/routing/RouteBuilder';
import NavigationPane from 'src/components/NavigationPane/NavigationPane';
import { FunctionComponentProps, FunctionComponentReturnType } from 'src/types/sharedReact';
import { ClientPath } from 'src/utils/routing/routes';
import {
    AuthenticationContextState,
    useAuthenticationContext
} from 'src/contextProviders/AuthenticationProvider/AuthenticationContext';
import Header from 'src/components/Header/Header';
import ErrorBoundary from 'src/containers/ErrorBoundary/ErrorBoundary';
import NotAuthorizedPage from 'src/pages/NotAuthorizedPage/NotAuthorizedPage';
import Footer from 'src/components/Footer/Footer';
import 'src/pages/PageTemplate/PageTemplate.css';
import LogoutPage from 'src/pages/LogoutPage/LogoutPage';
import LoginPage from 'src/pages/LoginPage/LoginPage';
import HomePage from 'src/pages/HomePage/HomePage';
import { handleAuthentication } from 'src/utils/handleAuthentication';
import { Environment } from 'src/types/environment';
import { useEnvironment } from 'src/contextProviders/EnvironmentProvider/EnvironmentContext';
import clsx from 'clsx';
import MobileMenu from 'src/components/NavigationPane/MobileMenu';
import { Feature } from 'src/utils/feature';
// TODO: THESE ARE DEPRECATED AND SHOULD BE REMOVED
import { PageMetadataPageTypes } from 'src/utils/routing/PageMetadata';
import { isUserAuthorizedToSeePage } from 'src/utils/userAuthorizationUtils';
import MaintenanceBanner from 'src/components/MaintenanceBanner/MaintenanceBanner';

export interface PageTemplateProps extends RouteComponentProps, FunctionComponentProps, PageMetadataPageTypes {
    Page: FunctionComponent;
    path?: ClientPath;
    requiredFeature: Feature;
}

declare const window: any;

function PageTemplate(
    {
        Page,
        requiredFeature,
        location,
        path,
        uri,

        // TODO: THESE ARE DEPRECATED AND SHOULD BE REMOVED
        isAdminPage,
        isReportPage,
        isDashboardPage,
        isOperationPage
    }: PageTemplateProps
): FunctionComponentReturnType {
    const { apiUrlBase }: Environment = useEnvironment();
    const routeBuilder: RouteBuilder = new RouteBuilder({
        location,
        path,
        uri,
        apiUrlBase
    });
    const {
        isAuthenticated,
        setIsAuthenticated,
        user,
        setUser
    }: AuthenticationContextState = useAuthenticationContext();



    const getIsUserAuthenticatedRoute = routeBuilder.api.getIsUserAuthenticated();



    const isLogoutPage: boolean = Page === LogoutPage;
    const isLoginPage: boolean = Page === LoginPage;
    const isHomePage: boolean = Page === HomePage;
    const [isAuthenticating, setIsAuthenticating] = useState<boolean>(isAuthenticated);
    useEffect(
        () => {
            const getUserRoute = routeBuilder.api.getUser();
            const loginRoute = routeBuilder.api.login();
            const partnersAccessRoute = routeBuilder.api.getAllDashboardsPartners();
            if (isLogoutPage || isLoginPage) {
                return;
            }
            if(!isAuthenticating) {
                setIsAuthenticating(true)
                handleAuthentication(getIsUserAuthenticatedRoute, getUserRoute, partnersAccessRoute, setIsAuthenticated, setUser, loginRoute, ()=>{setIsAuthenticating(false)});
            }
        },
        [
            isLogoutPage,
            isLoginPage,
            setIsAuthenticated,
            setUser,
            setIsAuthenticating
        ]
    );

    useEffect(() => {
        if (isAuthenticated) {
            window.sp?.('trackPageView', 'COPP:' + routeBuilder.client.getCurrentPageName());
        }
    }, [isAuthenticated]);

    return (isAuthenticated || user || isLogoutPage || isLoginPage) ? (
        <RouteBuilderContext.Provider value={routeBuilder}>
            <Header/>
            <div>
                {!isMobile ? <NavigationPane/> : <MobileMenu/>}
                { isAuthenticated && !isLogoutPage && !isLoginPage && <MaintenanceBanner /> }
                <div className="main-wrapper">
                    <Container component="main">
                        <ErrorBoundary>
                            <h1 id="page-template-title-element"
                                className={clsx(isMobile ? 'page-template-mobile-title' : 'page-template-desktop-title')}>
                                {routeBuilder.client.getCurrentPageName()}
                            </h1>
                            {
                                isUserAuthorizedToSeePage(
                                    user,
                                    requiredFeature,
                                    routeBuilder,
                                    isLogoutPage,
                                    isLoginPage,
                                    isAdminPage,
                                    isReportPage,
                                    isDashboardPage,
                                    isOperationPage,
                                ) ?
                                    <Page/> :
                                    <NotAuthorizedPage/>
                            }
                        </ErrorBoundary>
                    </Container>
                    <Footer/>
                </div>
            </div>
        </RouteBuilderContext.Provider>
    ) : <div className={"grv-spinner grv-spinner--active" } id="buttonSpinnerLight"></div>;
}

export default PageTemplate;
