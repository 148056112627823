import { FunctionComponentProps, FunctionComponentReturnType } from 'src/types/sharedReact';
import NavigationPaneContext, {
    NavigationPaneContextState
} from 'src/contextProviders/NavigationPaneProvider/NavigationPaneContext';
import React, { useState } from 'react';

type NavState = Pick<NavigationPaneContextState, 'activeKeys' | 'isNavigationPaneOpen' | 'isMobileMenuOpen'>;

function NavigationPaneProvider({ children }: FunctionComponentProps): FunctionComponentReturnType {
    const [navState, setNavState] = useState<NavState>({
        isNavigationPaneOpen: false,
        isMobileMenuOpen: false,
        activeKeys: []
    });

    function toggleNavigationPane(): void {
        setNavState((prevNavState: NavState) => ({
            ...prevNavState,
            isNavigationPaneOpen: !prevNavState.isNavigationPaneOpen
        }));
    }

    function toggleMobileMenu(): void {
        setNavState((prevNavState: NavState) => ({
            ...prevNavState,
            isMobileMenuOpen: !prevNavState.isMobileMenuOpen
        }));
    }

    function closeMobileMenu(): void {
        setNavState((prevNavState: NavState) => ({
            ...prevNavState,
            isMobileMenuOpen: false
        }));
    }

    function toggleActiveKey(activeKey: string): void {
        setNavState((prevNavState: NavState) => ({
            ...prevNavState,
            activeKeys: prevNavState.activeKeys.includes(activeKey) ?
                prevNavState.activeKeys.filter(x => x !== activeKey) :
                prevNavState.activeKeys.concat(activeKey)
        }));
    }

    return (
        <NavigationPaneContext.Provider
            value={{
                isNavigationPaneOpen: navState.isNavigationPaneOpen,
                toggleNavigationPane,
                isMobileMenuOpen: navState.isMobileMenuOpen,
                toggleMobileMenu,
                closeMobileMenu,
                activeKeys: navState.activeKeys,
                toggleActiveKey
            }}
        >
            {children || null}
        </NavigationPaneContext.Provider>
    );
}

export default NavigationPaneProvider;
